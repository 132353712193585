import * as React from 'react'
import { FormTypeSchema, FormVehicleRequest, TopicEnumSchema } from '@api/endpoints/forms'
import {
  FilesFormStep,
  InitialFormStep,
  SummaryStep,
  VehicleFormStep,
  VehicleWizardCommonState,
  VehicleWizardCommonStateActions,
  VehicleWizardDataStep,
  VehicleWizardFilesStep,
  VehicleWizardInitialStep,
  VehicleWizardSummaryStep
} from '@stores/forms'
import { VehicleInitialForm } from '@forms/VehicleInitialForm'
import { VehicleDetailsForm } from '@forms/VehicleDetailsForm'
import { VehiclePhotosForm } from '@forms/VehiclePhotosForm'
import { VehicleFormSuccess } from '@forms/VehicleFormSuccessNew'
import { useBuyoutStore } from './useBuyoutStore'
import styles from './BuyoutWizard.module.css'
import clsx from 'clsx'
import { BuyersContacts } from 'src/components/layout/BuyersContacts'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

export const BuyoutWizard: React.FC = () => {
  const store: VehicleWizardCommonState & VehicleWizardCommonStateActions = useBuyoutStore()
  const scrollToTop = (): void => {
    const elements = document.getElementsByClassName('scrollable-content')
    if (elements.length > 0) {
      const element = elements[0] as HTMLElement
      element.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const renderInitialStep = React.useCallback(
    (state: VehicleWizardInitialStep) => (
      <>
        <div className={styles.wizardGreetingMessage}>
          <span> {t('pages.buyback.greetingsMessage')}</span>
        </div>
        <div className={styles.wizardContainer}>
          <div className={styles.formContainer}>
            <VehicleInitialForm
              wizardStep={state.activeStep}
              formType={FormTypeSchema.enum.BUYOUT}
              defaultValues={undefined}
              onSubmitSuccess={(values) => {
                scrollToTop()
                store.switchStepToData(values)
              }}
              consentChecked={false}
            />
          </div>
        </div>
      </>
    ),
    [t, store]
  )

  const renderDataStep = React.useCallback(
    (state: VehicleWizardDataStep) => (
      <div className={clsx(styles.wizardContainer, 'growContainer')}>
        <VehicleDetailsForm
          wizardStep={state.activeStep}
          formId={state.formId}
          formType={FormTypeSchema.enum.BUYOUT}
          phoneNumber={state.phoneNumber}
          defaultValues={{
            mark: state.data.mark,
            model: state.data.model,
            carNumber: state.carNumber,
            mileage: undefined,
            location: undefined,
            requestedPrice: undefined,
            fullName: undefined,
            email: undefined
          }}
          onSubmitSuccess={(values: FormVehicleRequest) => {
            scrollToTop()
            store.switchStepToFiles(values)
          }}
        />
      </div>
    ),
    [store]
  )

  const renderFilesStep = React.useCallback(
    (state: VehicleWizardFilesStep) => (
      <div className={clsx(styles.wizardContainer, '')}>
        <VehiclePhotosForm
          wizardStep={state.activeStep}
          formType={FormTypeSchema.enum.BUYOUT}
          formId={state.formId}
          model={state.model}
          mark={state.mark}
          plateNumber={state.carNumber}
          onSubmitSuccess={(values) => {
            navigate(`${location.pathname}?finalStep=true`)
            scrollToTop()
            store.switchStepToSummary(values)
          }}
        />
      </div>
    ),
    [store, navigate, location]
  )

  const renderSummaryStep = React.useCallback(
    (state: VehicleWizardSummaryStep) => (
      <VehicleFormSuccess
        topic={TopicEnumSchema.enum.buyback}
        data={state}
        onReset={() => store.reset()}
        showContacts={false}
      />
    ),
    [store]
  )

  const stepContent = React.useMemo(() => {
    switch (store.activeStep) {
      case InitialFormStep:
        return renderInitialStep(store)
      case VehicleFormStep:
        return renderDataStep(store)
      case FilesFormStep:
        return renderFilesStep(store)
      case SummaryStep:
        return renderSummaryStep(store)
    }
  }, [store, renderDataStep, renderInitialStep, renderFilesStep, renderSummaryStep])

  return (
    <>
      {stepContent}
      {store.activeStep !== SummaryStep && <BuyersContacts />}
    </>
  )
}
